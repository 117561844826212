<template>
  <div class="user">
    <h1 class="title">{{ currentUser.user_display_name }}</h1>

    <h2 class="subtitle">{{ $t('user.editPassword') }}</h2>
    <el-form class="password-reset-form">
      <label for="new-password">{{ $t('user.newPassword') }}:</label>
      <el-input
        name="new-password"
        id="new-password"
        class="password-reset-form__input"
        :placeholder="$t('login.password')"
        v-model="passwordResetForm.firstPassword"
        show-password>
      </el-input>
      <label for="second-password">{{ $t('user.secondPassword') }}:</label>
      <el-input
        name="second-password"
        id="second-password"
        class="password-reset-form__input"
        :placeholder="$t('login.password')"
        v-model="passwordResetForm.secondPassword"
        show-password>
      </el-input>
      <el-button
        type="primary"
        class="password-reset-form__button"
        @click.prevent="setNewPassword"
        :disabled="!isPasswordValid"
        :loading="loading">
        {{ $t('user.editPassword') }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { usersApi } from '@/api';
import { mapGetters } from 'vuex';
import i18n from '@/i18n';

export default {
  name: 'User',
  props: ['username'],
  data() {
    return {
      passwordResetForm: {
        firstPassword: '',
        secondPassword: '',
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters('users', ['currentUser']),
    isPasswordValid() {
      if (
        (this.passwordResetForm.firstPassword !== ''
        && this.passwordResetForm.secondPassword !== '')
        && (
          this.passwordResetForm.firstPassword === this.passwordResetForm.secondPassword
        )
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async setNewPassword() {
      try {
        this.loading = true;
        const params = {
          password: this.passwordResetForm.firstPassword,
        };
        const response = await usersApi.setNewPassword(this.currentUser.user_id, params);
        this.loading = false;
        if (response.status === 200) {
          this.clearPasswordFields();
          this.$notify({
            type: 'success',
            dangerouslyUseHTMLString: true,
            title: i18n.t('user.successTitle'),
            message: `${i18n.t('user.successMessage')} ${this.currentUser.user_email}`,
          });
        }
      } catch (error) {
        this.loading = false;
        if (error && error.response) {
          this.$notify({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: error.response.data.message,
          });
        }
        throw error;
      }
    },
    clearPasswordFields() {
      this.passwordResetForm.firstPassword = '';
      this.passwordResetForm.secondPassword = '';
    },
  },
};
</script>
